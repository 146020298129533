import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnInit,
  Optional,
} from '@angular/core';
import {LayoutService} from '@scpc/modules/common/services';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {Router} from '@angular/router';
import {iOSVersion} from '@scpc/utils/io';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {USER_AGENT} from "../../../../../tokens";

@Component({
  selector: 'scp-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContentLoaderModule],
})
export class SkeletonLoaderComponent implements OnInit {

  protected isMobile: boolean;
  protected baseUrl: string = '';

  private layoutService: LayoutService = inject(LayoutService);
  private route: Router = inject(Router);
  private platform: Platform = inject(Platform);

  constructor(@Optional() @Inject(USER_AGENT) private readonly userAgent: string,
              @Inject(DOCUMENT) private readonly document: Document) {
  }


  public ngOnInit(): void {
    this.isMobile = !this.layoutService.isDesktop();
    const userAgent: string = this.userAgent || this.document.defaultView.navigator.userAgent;
    /* istanbul ignore next */
    if (this.platform.SAFARI && iOSVersion(userAgent)?.major < 14) {
      this.baseUrl = this.route.url;
    }
  }

}
